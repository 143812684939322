import { Link } from 'gatsby'
import React from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import { useState } from 'react'
import styles from './collapsible.module.scss'

function Collapsible({ open = false, title, children, borderBottom = false }) {
    const [isOpen, setIsOpen] = useState(open)
    const [height, setHeight] = useState(0)

    const elementRef = useRef()

    useEffect(() => {
        if (isOpen) setHeight(elementRef.current?.getBoundingClientRect().height)
        else setHeight(0)
    }, [isOpen])

    return (
        <div className={`${styles.wrapper} ${borderBottom ? styles.borderBottom : ''}`}>
            <Link className={styles.headline} to="#" onClick={() => setIsOpen(!isOpen)} >
                {title}
                <span className={isOpen ? 'icon-arrow-up' : 'icon-arrow-down'} />
            </Link>
            <div style={{ height }} className={styles.contentWrapper}>
                <div ref={elementRef}>
                    {
                        <div className="light pb">
                            {children}
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Collapsible